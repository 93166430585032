import request from '@/utils/request'

// 层次列表
export const layerListAPI = (params) => {
  return request({
    url: '/levelList',
    method: 'GET',
    params
  })
}

// 新增层次
export const newLevelAPI = (data) => {
  return request({
    url: '/addLevel',
    method: 'POST',
    data
  })
}

// 修改层次
export const modifyTheLevelAPI = (data) => {
  return request({
    url: '/levelSave',
    method: 'POST',
    data
  })
}

// 删除层次
export const deleteLeverAPI = (params) => {
  return request({
    url: '/levelDel',
    method: 'GET',
    params
  })
}
