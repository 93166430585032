<template>
  <div>
    <el-row>
      <el-col>
        <el-button type="primary" @click="levelAdd">
          <el-icon><CirclePlus /></el-icon> &nbsp;新增层次
        </el-button>
      </el-col>
    </el-row>

    <!-- 层次数据 -->

    <el-table
      :data="layerList"
      border
      style="width: 100%; margin-top: 10px"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="id" label="层次 ID " />
      <el-table-column prop="name" label="层次 " />
      <el-table-column prop="num" label="年数 " />
      <el-table-column label="操作" align="center">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >修改</el-button
          >
          <el-popconfirm
            title="是否删除该层次?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row)"
          >
            <template #reference>
              <el-button type="danger" size="small"> 删除 </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <addLevel
      v-model="layerAddEdit"
      :onLayerList="onLayerList"
      :levelItem="levelItem"
    ></addLevel>
  </div>
</template>

<script setup>
import addLevel from './components/addLevel.vue'
import { ref } from 'vue'
import { layerListAPI, deleteLeverAPI } from '@/api/level'
import { ElMessage } from 'element-plus'
const layerAddEdit = ref(false)

// 搜索数据源
const search = ref({
  page: 1,
  limit: 10,
  total: 0
})

// 新增层次
const levelAdd = () => {
  levelItem.value = {}
  layerAddEdit.value = true
}

/**
 *  表格 S
 */
// 列表数据
const layerList = ref([])
const onLayerList = async () => {
  const { data, total } = await layerListAPI(search.value)
  search.value.total = total
  layerList.value = data
}
onLayerList()

// 编辑
const levelItem = ref({})
const onEditBtn = (row) => {
  levelItem.value = row
  layerAddEdit.value = true
}

// 删除
const deleteBtn = async ({ id }) => {
  await deleteLeverAPI({ id })
  ElMessage.success('删除层次成功 。')
  onLayerList()
}

/**
 *  表格 E
 */

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onLayerList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onLayerList()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
